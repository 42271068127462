<template>
  <div class="datenschutz">
    <page-title class="page-title">Datenschutz</page-title>
    <v-page>
      <article class="text hyphenate">
        <p>
          Der Schutz Ihrer Privatsphäre ist mir sehr wichtig. Ich möchte Sie an dieser Stelle
          darüber informieren, ob und wie etwaige Daten beim Besuch auf meiner Website nach Stand
          aktueller Gesetzesregelungen erhoben oder verwendet werden.
        </p>
      </article>

      <h2 class="article-title">Erhebung und Verarbeitung persönlicher Daten</h2>
      <article class="text hyphenate">
        <p>
          Sie können meine Website besuchen, ohne Angaben zu Ihrer Person zu machen. Durch das Laden
          der Website werden aber automatisch Verbindungsdaten (insbesondere Ihre IP-Adresse) an den
          Server übermittelt.
        </p>
      </article>

      <h2 class="article-title">Zwecke der Verarbeitung</h2>
      <article class="text hyphenate">
        <p>Sämtliche Daten werden alleine zum Zweck der Bereitstellung der Website verarbeitet.</p>
      </article>

      <h2 class="article-title">Speicherung von Zugriffsdaten in Server-Logs</h2>
      <article class="text hyphenate">
        <p>
          Es werden nur Daten erhoben, die uns Ihr Internetbrowser automatisch übermittelt. Das
          System speichert dabei Zugriffsdaten in sogenannten Server-Logfiles. Diese Protokollierung
          dient internen systembezogenen und statistischen Zwecken. Aufgezeichnet werden die
          IP-Adresse des anfragenden Rechners, der Name der abgerufenen Seite bzw. Datei, Datum und
          Uhrzeit des Abrufs, die übertragene Datenmenge, die Information, ob der Abruf erfolgreich
          war, die Art des Web-Browsers (Internet Chrome, Explorer, Firefox, Opera etc.) und ggf.
          die Website, von der aufgerufen wurde (Referrer). Diese Daten werden von mir nicht
          verwendet, um einzelne Nutzer zu identifizieren. Diese Informationen dienen der
          Systemsicherheit (zum Schutz vor Missbrauch).
        </p>
        <p></p>
        <p>
          Darüber hinaus gehende Daten werden von uns nur dann erhoben, wenn Sie mit mir über meine
          Kontakt-Adressen (E-Mail oder Telefon) aufnehmen.
        </p>
      </article>

      <h2 class="article-title">Web Hosting</h2>
      <article class="text hyphenate">
        <p>
          Diese Website wird bei einem externen Dienstleister (Hetzner Online GmbH) gehostet. Zum
          Zweck der Bereitstellung und der Auslieferung der Website werden Verbindungsdaten durch
          den Hoster verarbeitet. Um eine datenschutzkonforme Verarbeitung dieser Daten
          sicherzustellen, wurde ein Vertrag zur Auftragsverarbeitung mit dem Hoster abgeschlossen.
        </p>
        <p></p>
        <p>Die Anschrift des Hosters lautet:</p>
        <p>Hetzner Online GmbH</p>
        <p>Industriestraße 25</p>
        <p>91710 Gunzenhausen</p>
      </article>

      <h2 class="article-title">Datenverarbeitung</h2>
      <article class="text hyphenate">
        <p>
          Bei der Kontaktaufnahme mit dem Anbieter (zum Beispiel per E-Mail) werden die Angaben des
          Nutzers zwecks Bearbeitung der Anfrage sowie für den Fall, dass Anschlussfragen entstehen,
          gespeichert. Diese Daten werden nicht ohne Einwilligung des Nutzers weitergegeben.
        </p>
      </article>

      <h2 class="article-title">Speicherdauer</h2>
      <article class="text hyphenate">
        <p>
          hre Daten werden solange gespeichert, wie dies zum Zweck der Anfragenbeantwortung, zur
          Erfüllung gesetzlicher (Aufbewahrungs-) Pflichten und zur Geltendmachung oder Abwehr von
          (drohenden) Rechtsansprüchen notwendig ist.
        </p>
      </article>

      <h2 class="article-title">Externe Links</h2>
      <article class="text hyphenate">
        <p>
          Meine Website enthält Links zu externen Webseiten Dritter. Auf Datenschutz-Richtlinien
          bzw. den Umgang mit geltenden Datenschutz-Gesetzen der auf unserer Website verlinkten
          externen Seiten haben wir keinerlei Einfluss. Diese liegen ausschließlich im
          Verantwortungsbereich der jeweiligen Verantwortlichen. Ab Aufruf (Klick) eines externen
          Links wechseln sie auch den Bereich der datenschutzrechtlichen Verantwortung.
        </p>
      </article>

      <h2 class="article-title">Betroffenenrechte</h2>
      <article class="text hyphenate">
        <p>
          Ihnen steht ein Recht auf Auskunft über die betreffenden personenbezogenen Daten sowie auf
          Berichtigung oder Löschung oder auf Einschränkung der Verarbeitung sowie ein
          Widerspruchsrecht gegen die Verarbeitung zu.
        </p>
        <p></p>
        <p>
          Bei Fragen zu Erhebung und Nutzung Ihrer Daten durch diese Website sowie Auskunft und für
          den Widerruf erteilter Einwilligungen wenden Sie sich gerne an den Inhaberin & Betreiber
          dieser Website:
        </p>
        <p></p>
        <p>Karl Grimmer</p>
        <p>Mariahilfer Straße 117/2/21, 1060 Wien</p>
        <p>grimmer.karl@gmail.com</p>
      </article>
      <article class="text hyphenate"></article>
    </v-page>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'
import VPage from '@/components/Page'

export default {
  name: 'Datenschutz',
  components: {
    PageTitle,
    VPage,
  },
}
</script>

<style scoped>
.page-title {
  height: min(320px, 50vh, 70vw);
}
</style>
